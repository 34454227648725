











































































import { Component, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import UsersMixin from "@/mixins/http/UsersMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";
import PrintTechnologySelect from "@/components/PrintTechnology/Select.vue";
import ColorInput from "@/components/ColorInput.vue";
import FileUploader from "@/components/FileUploader.vue";
import { Source, User } from "@/models";
import { Getter, namespace } from "vuex-class";
import RoleSelect from "@/components/User/RoleSelect.vue";
import SourceUserItem from "@/components/User/SourceUser.vue";
import { SourceUserPayload } from "@/models/SourceUser";
import { NoCache } from "@/utils/Decorators";
import SourceUser from "@/models/SourceUser.vue";
import { sourceStore } from "@/store/typed";

const updateTag = "update_user";
const createTag = "create_user";

@Component({
  components: {
    VModal,
    ColorInput,
    SourceUserItem,
    RoleSelect,
    FileUploader,
    PrintTechnologySelect,
  },
})
export default class EditUser extends Mixins(UsersMixin) {
  item: User = null;
  @Getter currentUser: User;

  @NoCache
  get newSourceUser() {
    if (!this.item.id) {
      return undefined;
    } else {
      return { userId: this.item.id, sourceId: null } as any;
    }
  }

  makePayload(sourceId: string) {
    return { userId: this.item.id, sourceId: sourceId.toString() };
  }

  onSourceAdded(item: any){
   this.item.sourceUsers.push(item)
  }

  onSourceDestroyed(item: Source){
  this.item.sourceUsers =   this.item.sourceUsers.filter((o) => o.id.toString() !== item.id.toString())
  }

  @Ref() readonly modal!: VModal;
  content = "";
  isNew = false;

  file: any = null;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  canceled!: any | null;

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.email &&
      this.item.role &&
      this.item.firstName &&
      this.item.lastName
    );
  }

  onRoleChanged(role: any) {
    this.item.role = role?.id;
  }

  title() {
    return `${this.isNew ? "Aggiungi" : "Modifica"} utente`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit("confirmed", this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(user: User) {
    this.isNew = false;
    this.modal.show();
    this.item = Object.assign({}, user);
  }

  reset() {
    this.item = null;
  }

  create(sourceId: string) {
    this.isNew = true;
    this.modal.show();
    this.item = new User();
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    try {
      const resp = this.isNew
        ? await this.createUser(
            { ...this.item, companyId: sourceStore.currentSource?.company?.id },
            createTag
          )
        : await this.updateUser(this.item, updateTag);
      const emitTag = this.isNew ? "created" : "updated";
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast("Utente salvato!");
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error",
      });
    }
  }
}
