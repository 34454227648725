












































































































import { Source } from "@/models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";
import EditModal from "@/components/User/Edit.vue";
import { Vue, Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UsersMixin from "@/mixins/http/UsersMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { User } from "@/models";
import VColor from "@/components/VColor.vue";
import EmptyView from "@/components/EmptyView.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_users";
const destroyTag = "destroy_user";

@Component({
  components: {
    EditModal,
    EmptyView,
    VColor,
    Search,
    VPageContentSticky,
    DataTable,
    ConfirmModal,
    RoundThumb,
  },
})
export default class UserList extends Mixins(UsersMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: User[] = [];
  page = 1;

  columns = [
    { name: "firstName", label: "Nome" },
    { name: "lastName", label: "Cognome" },
    { name: "role", label: "Ruolo" },
    { name: "email", label: "Email" },
    { name: "actions", label: "" },
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onCreated(item: User) {
    this.items.unshift(item);
  }

  onUpdated(item: User) {
    const index = this.items.findIndex((o) => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getUsers(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: any) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter((o) => id !== o.id);
  }

  openCreateModal() {
    (this.editModal as any).create(sourceStore.currentSource.id);
  }

  openEditModal(item: User) {
    (this.editModal as any).edit(item);
  }

  async openConfirmModal(item: User) {

     const message = "Sei sicuro di eliminare questo utente?";
    const confirmed = await (this.confirmModal as any).confirm(message);

    if (confirmed) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyUser(id, destroyTag));
    if (errors) {
      showToast("Errore durante l'eliminazione", { type: "error" });
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        roles: ["external_collaborator", "company_user"],
        company: sourceStore.currentSource?.company?.id,
        term: this.search,
      },
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getUsers(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
