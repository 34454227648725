











import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import UsersMixin from '@/mixins/http/UsersMixin';
import SourceSelect from "@/components/Source/Select.vue";
import { User } from '@/models';
import { SourceUserPayload } from '@/models/SourceUser';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { showToast } from '@/utils/Toast';

const createRequestTag = 'create_source_user'
const destroyRequestTag = 'destroy_source_user'

@Component({components: { SourceSelect}})
export default class SourceUser extends Mixins(UsersMixin) {

  @Prop({required: true}) readonly sourceUser!: SourceUserPayload;
  @RegisterHttp(createRequestTag) createRequest: RequestData;
  @RegisterHttp(destroyRequestTag) destroyRequest: RequestData;

  isNew = false;

  private item : SourceUserPayload = this.sourceUser;
  async create() {

    const data = {
      userId: this.sourceUser.userId,
      sourceId: this.sourceUser.sourceId
    }
    const sourceUser = await this.createSourceUser(this.item,createRequestTag);
    this.$emit('created',sourceUser);
    showToast('Creata associazione con il negozio')

  }

  async destroy() {

    const sourceUser = await this.destroySourceUser(this.item.id,destroyRequestTag);
    showToast('Associazione con il negozio rimossa')
    this.$emit('destroyed', sourceUser);
  }

  mounted() {
    this.isNew = !this.item.sourceId;
  }

  @Watch('sourceUser')
  onChanged(value: User) {
    this.item = this.sourceUser;
  }


  onSourceSelected(id: string){
    this.item.sourceId = id;
  }

  get loading() {

  return this.createRequest?.loading && this.destroyRequest?.loading;
  }


}
