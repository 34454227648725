
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_USER_URL = BASE_API_URL+"users"
const BASE_SOURCE_USER_URL = BASE_API_URL+"source_users"

@Component
export default class UsersMixin extends Vue {

  getUsers(data: any, tag: string) {
    return this.$httpGetQuery( BASE_USER_URL, tag, data);
  }

  getUser(id: number, tag: string) {
    return this.$httpGet(BASE_USER_URL+"/"+id, tag);
  }

  destroyUser(id: string|number, tag: string) {
    return this.$httpDelete(BASE_USER_URL+"/"+id, tag);
  }

  destroySourceUser(id: string|number, tag: string) {
    return this.$httpDelete(BASE_SOURCE_USER_URL+"/"+id, tag);
  }

  createUser(data: any, tag: string) {
    return this.$httpPost(BASE_USER_URL, tag, data);
  }

  createSourceUser(data: any, tag: string) {
    return this.$httpPost(BASE_SOURCE_USER_URL, tag, data);
  }

  updateUser(data: any, tag: string) {
     return this.$httpPut(BASE_USER_URL+"/"+data.id, tag, data);
  }
}
